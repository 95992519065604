import React, { useState, useEffect } from "react"

import Tones from "./Tones"
import Notes from "./Notes"
import "./App.css"

const Slides = [...Array(92).keys()]

const adjectives = [
  "Awesome",
  "Brave",
  "Caring",
  "Dependable",
  "Encouraging",
  "Funny",
  "Generous",
  "Handsome",
  "Inspirational",
  "Knowledgeable",
  "Loving",
  "Motivating",
  "One-of-a-Kind",
  "Patient",
  "Responsible",
  "Selfless",
  "Thoughtful",
  "Understanding",
  "Virtuous",
  "Wise",
]

const App = () => {
  const [loadCount, setLoadCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [note, setNote] = useState(-1)
  const [slide, setSlide] = useState(-1)
  const [adj, setAdj] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAdj((adj + 1) % adjectives.length)
    }, 500)
    return () => clearTimeout(timeout)
  }, [adj])

  useEffect(() => {
    if (loadCount === 30) {
      setTimeout(() => setLoading(false), 300)
    }
    const img = new Image()
    img.src = `/media/${loadCount}.jpg`
    img.onload = () => setLoadCount(loadCount + 1)
  }, [loadCount])

  const _onClick = () => {
    if (!loading) {
      const audio = new Audio(Tones[Notes[(note + 1) % Notes.length]])
      audio.play()
      setNote((note + 1) % Notes.length)
      setSlide((slide + 1) % (Slides.length + 1))
    }
  }

  useKeyUp(_onClick)

  const percentage = (loadCount / 30) * 100

  return (
    <div id="container">
      {loading ? (
        <div
          className={`loading ${loadCount === Slides.length ? "loaded" : ""}`}>
          <span
            className="text"
            style={{
              backgroundImage: `linear-gradient(90deg, rgba(255,255,255,1) ${percentage}%, rgba(255,255,255,0.6) ${percentage}%)`,
            }}>
            My Dad Is {adjectives[adj]}
          </span>
        </div>
      ) : (
        <div
          className={`slides ${loading ? "" : "visible"}`}
          onClick={_onClick}>
          <div className={`slide ${slide === -1 ? "visible" : ""}`}>
            <div className="message">Click On The Screen Or Press Any Key.</div>
          </div>
          {Slides.map((s) => (
            <div
              key={s}
              className={`slide ${slide === s ? "visible" : ""}`}
              style={{ backgroundImage: `url(/media/${s}.jpg)` }}
            />
          ))}
          <div className={`slide ${slide === 92 ? "visible" : ""}`}>
            <div className="message">Happy Father's Day</div>
          </div>
        </div>
      )}
    </div>
  )
}

const useKeyUp = (callback) => {
  useEffect(() => {
    window.addEventListener("keyup", callback)
    return () => window.removeEventListener("keyup", callback)
  }, [callback])
}

export default App
