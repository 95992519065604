export default [
  "E5",
  "E5",
  "D5",
  "C5",
  "D5",
  "G4",
  "A4",
  "C5",
  "F5",
  "E5",
  "D5",
  "E5",
  "E5",
  "D5",
  "C5",
  "D5",
  "G4",
  "A4",
  "C5",
  "F5",
  "E5",
  "D5",
  "G4",
  "A4",
  "B4",
  "C5",
  "C5",
  "C5",
  "C5",
  "B4",
  "A4",
  "A4",
  "B4",
  "B4",
  "A4",
  "G4",
  "G4",
  "D4",
  "E4",
  "F4",
  "G4",
]
